<script setup>
const items = [
  /*"./images/promos/big_slider_1.jpg",
  "./images/promos/big_slider1.png",
  "./images/promos/big_slider1.png",*/
  './images/promos/promo1.jpg',
  './images/promos/promo2.jpg',
  './images/promos/promo3.jpg',
  './images/promos/promo4.jpg',
  /*'https://cdn2.vectorstock.com/i/1000x1000/68/96/sale-concept-vertical-banner-design-discount-vector-34986896.jpg',
  'https://img.freepik.com/vector-premium/banner-vertical-promocion-viajes_8462-183.jpg'*/
];

const { isMobile } = useDevice();
const itemsToShow = ref(isMobile ? 1 : 4);
</script>

<template>
  <div class="w-full flex h-full">
    <V3Carousel :items-to-show="itemsToShow" :autoplay="4000" :transition="700" wrap-around>
      <V3Slide v-for="slide in items" :key="slide">
        <div class="carousel__item">
          <div class="overflow-hidden rounded-lg cursor-pointer">
            <img :src="slide" alt="casa1" class="hover:scale-105 duration-200 rounded-lg h-[600px]" />
          </div>
        </div>
      </V3Slide>

      <template #addons>
        <V3Navigation />
        <V3Pagination />
      </template>
    </V3Carousel>
  </div>
</template>

<style scoped>
.carousel__item {
  width: 100%;
  background-color: theme('colors.white');
  color: theme('colors.white');
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.carousel__slide {
  padding: 10px;
}
</style>
