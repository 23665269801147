<template>
  <div class="overflow-hidden">
    <main class="hero-image h-[780px]">
      <div class="flex flex-col w-full max-w-6xl mx-auto py-20 justify-center items-center">
        <div class="flex justify-center">
          <hr class="w-48 h-2 my-8 bg-gray-200 border-0" />
        </div>
        <h2 class="font-semibold text-white text-4xl text-center">SUPER PRIMAVERA</h2>
        <h1 class="font-extrabold text-white text-2xl md:text-6xl py-2 text-center">DESCUENTOS EXCLUSIVOS: AHORRE AHORA MISMO!</h1>
        <h3 class="font-semibold text-white text-xl text-center py-4">Descubre en Super Primavera una variedad de productos de alta calidad, servicio personalizado y promociones exclusivas. Disfruta de descuentos especiales en alimentos, bebidas, productos de limpieza, higiene personal y hortalizas.</h3>
        <NuxtLink to="#promociones">
          <button class="bg-primavera-500 text-gray-700 text-xl p-4 rounded-lg font-bold mt-3">Nuestras Promociones</button>
        </NuxtLink>
      </div>
    </main>
    <!-- <section class="h-[340px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        style="background-color: #fdfdfd"
      >
        <path
          fill="#747473"
          fill-opacity="1"
          d="M0,96L1440,256L1440,320L0,320Z"
        ></path>
      </svg>
    </section>-->
    <IndexBigSlider id="promociones" />
    <section v-if="false">
      <div class="flex w-full mx-auto bg-cinza1 justify-center items-center p-4">
        <div class="flex flex-col justify-center items-center">
          <div class="flex justify-center items-center py-4">
            <p class="text-center text-4xl text-white">CONOZCA NUESTRAS PROMOCIONES PERMANENTES</p>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10">
            <div class="flex flex-col gap-2 hover:scale-105">
              <div>
                <NuxtImg src="/images/promos/promo1.jpg" alt="Promocion 1" loading="lazy" />
              </div>
            </div>
            <div class="flex flex-col gap-2 hover:scale-105">
              <div>
                <NuxtImg src="/images/promos/promo2.jpg" alt="Promocion 2" loading="lazy" />
              </div>
            </div>
            <div class="flex flex-col gap-2 hover:scale-105">
              <div>
                <NuxtImg src="/images/promos/promo3.jpg" alt="Promocion 3" loading="lazy" />
              </div>
            </div>
            <div class="flex flex-col gap-2 hover:scale-105">
              <div>
                <NuxtImg src="/images/promos/promo4.jpg" alt="Promocion 4" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="flex w-full mx-auto bg-cinza1 justify-center items-center p-4">
        <div class="flex flex-col justify-center items-center">
          <div class="flex justify-center items-center py-4">
            <p class="text-center text-4xl text-white">CONOZCA NUESTRA SUCURSAL MAYORISTA</p>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10">
            <div class="flex flex-col gap-2 hover:scale-105">
              <div>
                <NuxtImg src="/images/sucursal_mayorista/img1.jpeg" alt="Sucursal Mayorista" loading="lazy" />
              </div>
            </div>
            <div class="flex flex-col gap-2 hover:scale-105">
              <div>
                <NuxtImg src="/images/sucursal_mayorista/img2.jpeg" alt="Sucursal Mayorista" loading="lazy" />
              </div>
            </div>
            <div class="flex flex-col gap-2 hover:scale-105">
              <div>
                <NuxtImg src="/images/sucursal_mayorista/img3.jpeg" alt="Sucursal Mayorista" loading="lazy" />
              </div>
            </div>
            <div class="flex flex-col gap-2 hover:scale-105">
              <div>
                <NuxtImg src="/images/sucursal_mayorista/img4.jpeg" alt="Sucursal Mayorista" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.815), rgba(0, 0, 0, 0.61)), url('/images/mercado.jpg');
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
</style>
